<template>
  <div class="personInfo_Public">
    <div
      class="personInfo_Public-content"
      :style="{ 'min-height': clientH, 'background-color': '#ffffff' }"
    >
      <div
        :style="{
          'min-height': clientH,
          display: 'flex',
          'background-color': '#ffffff'
        }"
      >
        <div style="min-width:274px;border-right: 1px solid #ebebeb;">
          <div class="infoBox">
            <img
              :src="
                info.Photo
                  ? info.Photo
                  : 'https://smart-resource.sikegroup.com/image/jpg/avater.png'
              "
              alt="照片"
            />
            <div class="title">
              {{ info.Name }}
              <span
                class="iconSex "
                :style="{ background: info.Sex == 1 ? '#FF4081' : '#13C2C2' }"
              >
                <a-icon v-if="info.Sex == 1" type="woman" />
                <a-icon v-else type="man" />
              </span>
            </div>
            <div class="item">
              工作经验:<span class="info">{{
                info.WorkingLife ? info.WorkingLife : ''
              }}</span>
            </div>
            <div class="item">
              学历:<span class="info">{{ info.Qualification }}</span>
            </div>
            <div class="item">
              学校:<span class="info">{{ info.GraduatedFrom }}</span>
            </div>
            <div class="item">
              专业:<span class="info">{{ info.Speciality }}</span>
            </div>
            <div class="item">
              手机:<span class="info">{{ info.Mobile }}</span>
            </div>
            <div class="item">
              邮箱:<span class="info">{{ info.Email }}</span>
            </div>
            <div class="item">
              现居住地:<span class="info">{{ info.CurrentResidence }}</span>
            </div>
            <div class="item">
              应聘岗位:<span class="info">{{ info.Position }}</span>
            </div>
            <div class="item">
              简历来源:<span class="info">{{ info.Source }}</span>
            </div>
            <div class="item">
              录入人:<span class="info">{{ info.CreateUserName }}</span>
            </div>
            <div
              class="item"
              style="min-height:30px;line-height: 24px;margin-bottom: 20px;"
            >
              个人标签:
              <a-popconfirm
                v-for="(list, k) in min_titles"
                :key="k"
                :title="`是否删除${list.TagName}标签?`"
                @confirm="confirm(list.Id, k)"
                okText="是"
                cancelText="否"
              >
                <span class="info btnBar">{{ list.TagName }}</span>
              </a-popconfirm>

              <a-icon
                type="plus-circle"
                class="ant-menu-item-selected"
                @click="personTitle = true"
                style="margin-left:10px;"
              />
            </div>

            <div class="" style="margin-top:12px;">
              <span style="margin-top:-4px;">添加个人照片:</span>
              <img
                :src="
                  info.Photo
                    ? info.Photo
                    : 'https://smart-resource.sikegroup.com/image/jpg/avater.png'
                "
                style="width:40px;height:40px;margin-left:10px;vertical-align: top;"
              />
            </div>
            <a-upload
              accept="image/*"
              :fileList="[]"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
            >
              <a-button
                icon="picture"
                style="border-radius:15px;margin-top:20px;"
                class="border ant-menu-item-selected "
              >
                选择照片</a-button
              >
            </a-upload>
          </div>
        </div>
        <div class="person_header">
          <div class="flexBox">
            <div>
              <span
                :class="{
                  tabs: true,
                  'bt-3': indexTab == 1,
                  'ant-menu-item-selected': indexTab == 1
                }"
                @click="tabFn(1)"
                >面试记录</span
              >
              <span
                :class="{
                  tabs: true,
                  'bt-3': indexTab == 2,
                  'ant-menu-item-selected': indexTab == 2
                }"
                @click="tabFn(2)"
                >个人资料</span
              >
            </div>
            <div>
              <a-button
                size="small"
                style="border-radius:15px;margin-left:16px;margin-top:4px;"
                class="border ant-menu-item-selected "
                @click="drawerFn('面试邀请')"
              >
                面试邀请</a-button
              >
              <a-button
                size="small"
                style="border-radius:15px;margin-left:16px;margin-top:4px;"
                class="border ant-menu-item-selected "
                @click="drawerFn('确聘申请')"
              >
                确聘申请</a-button
              >
              <a-button
                size="small"
                style="border-radius:15px;margin-left:16px;margin-top:4px;"
                class="border ant-menu-item-selected "
                @click="drawerFn('发offer')"
              >
                发offer</a-button
              >
            </div>
          </div>
          <a-divider style="margin-top:-4px;" />
          <router-view></router-view>
        </div>
      </div>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>

    <a-drawer
      :title="controlType"
      width="380"
      placement="right"
      :closable="true"
      @close="onClose"
      :visible="drawerBool"
    >
      <div v-if="controlType == '面试邀请'">
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="跟踪类型">
            <a-select v-model="posts_type" placeholder="请选择">
              <a-select-option
                v-for="item in posts_type_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试岗位">
            <a-tree-select
              style=""
              :treeData="posts_type_arr"
              :value="posts_type"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              treeCheckable
              :showCheckedStrategy="SHOW_PARENT"
              searchPlaceholder="请选择岗位"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="公司联系人">
            <person v-bind="contackObj" @getData="contackPerson"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试日期">
            <a-range-picker
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试时间">
            <a-range-picker
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试邮件模板">
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试评价模板">
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试地址">
            <!-- <a-select v-model="CVsource" placeholder="请选择简历来源">
                <a-select-option v-for="item in CVsource_arr" :key="item.key" :value="item.key">{{item.name}}</a-select-option> 
            </a-select> -->
          </a-form-item>

          <a-form-item label="面试官(可多选)">
            <person v-bind="managementObj" @getData="managementPerson"></person>
          </a-form-item>
          <a-form-item label="抄送人(可多选)">
            <person v-bind="CCObj" @getData="CCPerson"></person>
          </a-form-item>
          <a-form-item label="备注说明">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div v-else-if="controlType == '确聘申请'">
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="入职岗位">
            <a-tree-select
              style=""
              :treeData="posts_type_arr"
              :value="posts_type"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              treeCheckable
              :showCheckedStrategy="SHOW_PARENT"
              searchPlaceholder="请选择岗位"
            />
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="审批人">
            <person v-bind="approveObj" @getData="approvePerson"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="部门负责人">
            <person v-bind="contackObj" @getData="contackPerson"></person>
          </a-form-item>
          <a-form-item label="抄送人(可多选)">
            <person v-bind="CCObj" @getData="CCPerson"></person>
          </a-form-item>
          <a-form-item label="备注说明">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <!-- offer -->
      <div v-else>
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="入职部门">
            <person v-bind="departmentObj" @getData="departmentPerson"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="入职岗位">
            <a-tree-select
              style=""
              :treeData="posts_type_arr"
              :value="posts_type"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              treeCheckable
              :showCheckedStrategy="SHOW_PARENT"
              searchPlaceholder="请选择岗位"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="公司联系人">
            <person v-bind="contackObj" @getData="contackPerson"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="入职日期">
            <a-range-picker
              style="width:220px;"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="聘用形式">
            <!-- <a-select v-model="CVsource" placeholder="请选择简历来源">
                    <a-select-option v-for="item in CVsource_arr" :key="item.key" :value="item.key">{{item.name}}</a-select-option> 
                </a-select> -->
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="offer有效期">
            <!-- <a-select v-model="CVsource" placeholder="请选择简历来源">
                    <a-select-option v-for="item in CVsource_arr" :key="item.key" :value="item.key">{{item.name}}</a-select-option> 
                </a-select> -->
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="试用期">
            <a-select v-model="timer_type" placeholder="请选择">
              <a-select-option
                v-for="item in timer_type_arr"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="合同期限">
            <a-select v-model="year_type" placeholder="请选择合同期限">
              <a-select-option
                v-for="item in year_type_arr"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="上班地址">
            <!-- <a-select v-model="CVsource" placeholder="请选择简历来源">
                    <a-select-option v-for="item in CVsource_arr" :key="item.value" :value="item.value">{{item.name}}</a-select-option> 
                </a-select> -->
          </a-form-item>
          <a-form-item label="抄送人(可多选)">
            <person v-bind="CCObj" @getData="CCPerson"></person>
          </a-form-item>
          <a-form-item label="备注说明">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div class="drawer-btns">
        <a-button
          type="default"
          v-show="controlType != '确聘申请'"
          class="drawer-btn"
          >简历预览</a-button
        ><a-button class="drawer-btn" type="primary">提 交</a-button>
      </div>
    </a-drawer>
    <a-modal title="添加标签" centered v-model="personTitle" @ok="subTitle()">
      <a-input
        type="text"
        style="margin-bottom:10px;"
        v-model="addtitles"
        placeholder="多个用英文','隔开"
      />
      <span
        class="info btnBar"
        v-for="(list, k) in min_titles_arr"
        :key="k"
        @click="selectTitles(list)"
        >{{ list }}</span
      >
    </a-modal>
  </div>
</template>
<script>
import { TreeSelect } from 'ant-design-vue'
import { mapState, mapGetters } from 'vuex'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import person from '@/components/SelectPersonnel/SelectPersonnel' //选人
import {
  __options_Marriage,
  __arr_experience,
  __arr_Education,
  __arr_worktime,
  __arr_province,
  __dateTransation
} from '@/assets/js/public_ren'
import moment from 'moment'
export default {
  name: 'personInfo_Public',
  components: {
    person
  },
  data () {
    return {
      SHOW_PARENT,
      id: '', //简历Id
      spinning: false,
      clientH: '400px',
      infoImg: [],
      info: {},
      indexTab: 1,
      personTitle: false, //添加标签 模态框
      min_titles: [], //添加标签（查到的标签）
      min_titles_arr: ['男婶', '超级大咖', '搞笑', '牛b'],
      addtitles: undefined, //添加标签
      fileList: [], //头像
      contackObj: {
        //选联系人
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      contack_ren: [], //选联系人 以及部门负责人
      CCObj: {
        //选抄送人
        rank: 0, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      CC_ren: [], //抄送人
      approveObj: {
        //选审批人
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      approve_ren: [], //审批人
      managementObj: {
        //选面试官
        rank: 0, //职级 0为不选，1为选
        nums: 10, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      management_ren: [], //面试官
      departmentObj: {
        //入职部门
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 2, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      department_ren: [], //面试官

      drawerBool: false, //侧边栏的显隐
      controlType: '', //侧边栏的标题名
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      startDate: '',
      endDate: '',

      timer_type_arr: [
        { value: '3个月', name: '3个月' },
        { value: '1个月', name: '1个月' },
        { value: '2个月', name: '2个月' }
      ],
      timer_type: undefined, //试用期
      year_type_arr: [
        { value: '2年', name: '2年' },
        { value: '3年', name: '3年' },
        { value: '5年', name: '5年' }
      ],
      year_type: undefined, //合同期限
      posts_type_arr: [], //岗位
      posts_type: [],
      remarks: undefined //备注
    }
  },

  created () {
    let that = this
    //    console.log(that.$route.path)
    if (that.$route.path == '/personinfo/personResume') {
      that.indexTab = 2
    } else {
      that.indexTab = 1
    }
    //    id:5d37238944ba905e9c37a7045e4653f4
    that.id = that.$route.query.id
    this.$axios.post(9970, { Id: that.id }, res => {
      if (res.data.code == 1) {
        // that.info
        let info = res.data.data
        let index = info.Qualification ? info.Qualification : 0
        let arr = __arr_Education()
        if (index > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].value == index) {
              info.Qualification = arr[i].name
            }
          }
        }
        that.$store.state.app.personInfo = info
      } else {
        that.$message.error(res.data.msg)
      }
    })
    //查个人标签
    that.$axios.get(9969, { Id: that.id }, res => {
      if (res.data.code == 1) {
        that.min_titles = res.data.data
      }
    })
  },

  computed: {
    ...mapGetters(['personInfo']),
    mapboxMap1 () {
      return this.personInfo
    }
  },
  watch: {
    mapboxMap1 (newData, oldData) {
      this.info = newData
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 156 + 'px'
  },
  methods: {
    //选个人标签
    selectTitles (val) {
      if (this.addtitles) {
        this.addtitles = this.addtitles + ',' + val
      } else {
        this.addtitles = val
      }
    },
    //删除个人标签
    confirm (val, index) {
      console.log(val)
      let that = this
      that.$axios.post(9967, { tagId: val }, res => {
        console.log(res)
        if (res.data.code == 1) {
          that.min_titles.splice(index, 1)
          that.$message.text('删除成功')
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //添加个人标签
    subTitle () {
      let that = this
      if (!that.addtitles) {
        that.$message.text('不能为空!')
        return false
      }
      that.spinning = true
      that.$axios.post(
        9968,
        {
          Id: that.id,
          TagName: that.addtitles,
          CreateUserId: that.__USER__.uid
        },
        res => {
          that.spinning = false
          console.log(res)
          if (res.code == 1) {
            that.$message.succerr('添加成功')
            let obj = { TagName: that.addtitles }
            obj.Id = res.data.tagId
            console.log(obj)
            that.min_titles.push(obj)
            console.log(that.min_titles)
          } else {
            that.$message.error(res.data.msg)
          }
        }
      )
    },
    moment,
    //时间
    changeTime (value) {
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },
    //相片上传
    handleRemove (file) {
      // const index = this.fileList.indexOf(file);
      // const newFileList = this.fileList.slice();
      // newFileList.splice(index, 1);
      // this.fileList = newFileList;
      // console.log(newFileList)
    },
    //相片上传
    beforeUpload (file) {
      console.log(file)
      let that = this
      const isIMG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isIMG) {
        this.$message.error('请上传jpg或png格式的照片')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('图片不能大于2MB!')
        return false
      }
      this.__uploadFile__([file]).then(res => {
        //  console.log(res);
        let obj = res[0]
        if (!obj.FileUrl) {
          this.$message.error('头像上传失败!')
        }
        //  that.info.Photo = obj.FileUrl;
        that.UpPhoto(obj.FileUrl)
      })
      return false
    },
    UpPhoto (url) {
      let that = this
      //   console.log(url,that.id)
      that.$axios.post(9965, { Id: that.id, PhotoUrl: url }, res => {
        if (res.data.code == 1) {
          //  that.$store.state.app.personInfo.Photo = '';
          that.$store.state.app.personInfo.Photo = url
          that.$message.success('照片保存成功')
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //tab切换
    tabFn (num) {
      let that = this
      this.indexTab = num
      that.$router.push({
        path:
          num == 1
            ? '/personinfo/personManagement'
            : '/personinfo/personResume',
        query: {
          id: that.id
        }
      })
    },

    onClose () {
      //简历导入 引入的侧边栏隐藏
      this.controlType = ''
      this.drawerBool = false
    },
    drawerFn (type) {
      this.controlType = type
      this.drawerBool = true
      if (!sessionStorage.getItem('posts_type_arr')) {
        this.$axios.get(8007, {}, res => {
          console.log(res)
          if (res.data.code == 1) {
            this.posts_type_arr = res.data.data
            window.sessionStorage.setItem(
              'posts_type_arr',
              JSON.stringify(res.data.data)
            )
          } else {
            this.$message.error('获取数据失败!')
          }
        })
      } else {
        this.posts_type_arr = JSON.parse(
          window.sessionStorage.getItem('posts_type_arr')
        )
      }
    },
    //岗位
    onChangeTree (val) {
      console.log(val)
      this.posts_type = val
    },
    //选人
    contackPerson (value) {
      // console.log(`选联系人 ${value}`);
      this.contack_ren = value
    },
    CCPerson (value) {
      // console.log(`选抄送人 ${value}`);
      this.CC_ren = value
    },
    approvePerson (value) {
      // console.log(`选审批人 ${value}`);
      this.approve_ren = value
    },
    managementPerson (value) {
      // console.log(`选面试官 ${value}`);
      this.management_ren = value
    },
    departmentPerson (value) {
      // console.log(`选入职部门 ${value}`);
      this.department_ren = value
    }
  }
}
</script>
<style lang="less" scoped>
.personInfo_Public {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 0px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 1.1;
    margin: 16px 0px 10px;
  }
  .item {
    line-height: 30px;
  }

  .personInfo_Public-content {
    width: 100%;
    .infoBox {
      width: 270px;
      padding: 30px 0px 0px 20px;
      color: #999999;
      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }

      .iconSex {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        font-size: 8px;
        text-align: center;
        line-height: 18px;
        color: #ffffff;
        // margin-top: 0px;
        vertical-align: middle;
      }
    }
  }

  .person_header {
    .flexBox {
      display: flex;
      justify-content: space-between;
    }
    .tabs {
      padding: 4px 0px;
      margin: 4px 26px 4px 0px;
      display: inline-block;
    }
    width: 100%;
    padding: 30px 20px;
  }
}
.info {
  margin-left: 10px;
  color: #333333;
}
.btnBar {
  color: #ffffff;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #f2a654;
  font-size: 8px;
  display: inline-block;
  height: 16px;
  line-height: 15px;
}
.ant-form-item {
  margin-bottom: 14px;
}
</style>
