function __options_Marriage (){
    return [
    { label: '未婚', value: '未婚' },
    { label: '已婚', value: '已婚' },
    { label: '离异', value: '离异' },
];
}
function __arr_experience (){
    return ['应届生','1年以内','1-3年','3-5年','5年以上'];
}
function __arr_Education (){
    return [{name:'高中及以下',value:2},{name:'大专',value:4},{name:'本科',value: 6},{name:'研究生',value: 8},]
}
function __arr_worktime (){
    return ['随时','一周','15天','一月','待定'];
}
function __arr_province (){
    return [
    "河北省","山西省","辽宁省","吉林省","黑龙江省","江苏省","浙江省","安徽省","福建省","江西省","山东省","河南省","湖北省","湖南省","广东省","海南省","四川省","贵州省","云南省","陕西省","甘肃省","青海省","台湾省","北京市","天津市","上海市","重庆市","内蒙古自治区","广西壮族自治区","宁夏回族自治区","新疆维吾尔自治区","西藏自治区","香港特别行政区","澳门特别行政区",
];
}
//时间格式处理
function __dateTransation(value){
    let startDate = value[0]._d;
    let endDate = value[1]._d;
    let date1 = new Date(startDate);
    let arr=[];
    arr[0] = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1)+'-'+(date1.getDate()<10?'0'+date1.getDate():date1.getDate());
    let date2 = new Date(endDate);
    arr[1] = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1)+'-'+(date2.getDate()<10?'0'+date2.getDate():date2.getDate());
    return arr;
}

//员工状态
function __persionnal_Status(){
     return [{name:'在职',value:'0'},{name:'试用期',value:'4'},{name:'兼职',value:'3'},{name:'不在岗',value:'2'},{name:'离职',value:'1'}];
}
function __fast_Select(){
    return [
        {name:'今天',value:'1'},
        {name:'昨天',value:'2'},
        {name:'本周',value:'3'},
        {name:'上周',value:'4'},
        {name:'本月',value:'5'},
    ]
}
//离职原因
function __quit_type(){
    return [
        {name:'正常解除',value:'1'},
        {name:'公司发展',value:'2'},
        {name:'工作内容',value:'3'},
        {name:'淘汰',value:'4'},
        {name:'个人原因',value:'5'},
    ];
}
 //紧急程度
//  if(sessionStorage.getItem('pressing_type_arr')){
//     this.pressing_type_arr = JSON.parse(sessionStorage.getItem('pressing_type_arr'));
// }else{
//     this.$axios.get(8006,{},res=>{
//         if (res.data.code==1) {
//             this.pressing_type_arr = res.data.data;
//         }else {
//             this.$message.error(res.data.msg);
//         }
//     });
// }

//可见范围
function __show_range(){
    return [
        {value:0,name:"公开"},
        {value:1,name:"部分人可见"},
        {value:2,name:"仅参与人可见"}
    ]    
}
function ajax(){
    this.$axios.get(9943,{},res=>{
        console.log(res.data.data)
    })
}

function days(type){
    let myDate = new Date();
    let year = myDate.getFullYear();    //获取完整的年份(4位,1970-????)
    let month = (myDate.getMonth()+1)>9?(myDate.getMonth()+1):'0'+(myDate.getMonth()+1);       //获取当前月份(0-11,0代表1月)
    let day = myDate.getDate()>9?myDate.getDate():'0'+myDate.getDate();        //获取当前日(1-31)
    return type=='month'?year+'-'+month:(year+'-'+month+'-'+day);
}
export {
    __options_Marriage,
    __arr_experience,
    __arr_Education,
    __arr_worktime,
    __arr_province,
    __dateTransation,//插件时间格式化公共方法
    __persionnal_Status,
    __fast_Select,
    __quit_type,//离职类型
    __show_range,
    ajax,

    days,//获取当前日期or当前月份
}